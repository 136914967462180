import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type sitesByCategories = RouterOutput['sites']['sitesByCategories']

type ErrorOutput = TRPCClientError<AppRouter>

export default function useGetSitesByCategories(options: { page?: number, pageSize?: number }) {
  const { page = 1, pageSize = 10 } = options
  const { $client } = useNuxtApp()
  return useAsyncData<sitesByCategories, ErrorOutput>(() => $client.sites.sitesByCategories.query({
    page,
    pageSize
  }))
}
