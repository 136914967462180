<template>
  <div class="dark:bg-gray-900">
    <div
      class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8"
    >
      <div
        class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-4"
      >
        <div>
          <p
            class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-blue-100"
          >
            {{ $t("films_gratis") }}
          </p>
        </div>
        <h2
          class="max-w-lg mb-6 dark:text-gray-300 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
        >
          <span class="relative inline-block">
            <svg
              class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              fill="currentColor"
              viewBox="0 0 52 24"
            >
              <defs>
                <pattern
                  id="34f481be-159a-4846-821d-9ca19fb6bcc5"
                  height=".30"
                  width=".135"
                  x="0"
                  y="0"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#34f481be-159a-4846-821d-9ca19fb6bcc5)"
                height="24"
                width="52"
              />
            </svg>
          </span>
          {{ $t("maniere_la_plus_rapide_pour_trouver_des_films_gratuits") }}
        </h2>
        <p class="text-base dark:text-gray-300 text-gray-700 md:text-lg">
          {{ $t("une_liste_actualisee") }}
        </p>
      </div>
      <TabMenu current-tab="tabRanking" />
      <Ranking />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'

import Ranking from '~/components/Ranking.vue'
import { useSiteStore } from '~~/stores/sites'

const siteStore = useSiteStore()
const { sites, isSiteListLoaded } = storeToRefs(siteStore)

if (!isSiteListLoaded.value) {
  const { data } = await useGetSites()
  sites.value = data.value
  isSiteListLoaded.value = true
}
</script>
