import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type sitesByTypes = RouterOutput['sites']['sitesByTypes']

type ErrorOutput = TRPCClientError<AppRouter>

export default function useGetSitesByTypes(options: { page?: number, pageSize?: number }) {
  const { page = 1, pageSize = 10 } = options
  const { $client } = useNuxtApp()
  return useAsyncData<sitesByTypes, ErrorOutput>(() => $client.sites.sitesByTypes.query({
    page,
    pageSize
  }))
}
